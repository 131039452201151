export const projectsList = [

  // Example: //
  // {
  //   name: 'Sokoban',
  //   date: '2021',
  //   slug: 'sokoban',
  //   tagline: 'A web-based Sokoban implementation.',
  //   url: 'https://taniarascia.github.io/sokoban',
  //   writeup: '/sokoban-game',
  //   highlight: true,
  // },

  {
    name: 'Text Bot GPT',
    date: '2023',
    slug: 'text-bot-GPT',
    tagline: 'Text Bot for iMessage inspired by South Park',
    highlight: true,
    writeup: '/text-bot-gpt',
  },
  {
    name: 'Rosada Web Scraper',
    date: '2022',
    slug: 'rosada-WebScraper',
    tagline: 'Web Scraper for gathering financial data from a Brazilian website',
    highlight: true,
    writeup: '/rosada-webscraper',
  },
  {
    name: 'Kahoot Bot GPT',
    date: '2023',
    slug: 'kahoot-bot-gpt',
    tagline: 'Bot powered by selenium and GPT-4 to automatically answer kahoot questions',
    // url: 'https://franco-lopez.com/kahoot-bot-gpt/#demo',
    writeup: '/kahoot-bot-gpt', 
    highlight: true,
  },
]
